// extracted by mini-css-extract-plugin
export var swiperComponent = "styles-module--swiper-component--T06kV";
export var swiperImages = "styles-module--swiper-images--2Fqg0";
export var slideImageInner = "styles-module--slide-image-inner--3sTH4";
export var isActive = "styles-module--is-active--21SYF";
export var swiperText = "styles-module--swiper-text--q9u3Z";
export var slideText = "styles-module--slide-text--26_z-";
export var isPlaceholder = "styles-module--is-placeholder--2Apo8";
export var containerInner = "styles-module--container-inner--3tZzD";
export var swiperNav = "styles-module--swiper-nav--2peLl";
export var prev = "styles-module--prev--180Lb";
export var next = "styles-module--next--18_Vs";
export var pagination = "styles-module--pagination--1tdXA";
export var hasButton = "styles-module--has-button--13W7F";