import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination])

const Slideshow = ({ items, id, hasButton = false }) => {
  const [swiper, setSwiper] = useState(null)
  const [textIndex, setTextIndex] = useState(0)
  const handleSlideChange = () => {
    if (swiper) {
      setTextIndex(swiper.realIndex)
    }
  }

  return (
    <div className={styles.swiperComponent}>
      <div className='container'>
        <div className={styles.containerInner}>
          <SwiperImages
            items={items}
            id={id}
            setSwiper={setSwiper}
            handleSlideChange={handleSlideChange}
          />
          <SwiperText
            items={items}
            id={id}
            hasButton={hasButton}
            textIndex={textIndex}
          />
        </div>
      </div>
    </div>
  )
}

const SwiperImages = ({ items, id, setSwiper, handleSlideChange }) => {
  let initialSlidesPerView = 1
  if (typeof window !== 'undefined') {
    if (window.innerWidth >= 601) {
      initialSlidesPerView = 2
    }
    if (window.innerWidth >= 1025) {
      initialSlidesPerView = 3
    }
  }

  return (
    <div className={styles.swiperImages}>
      <div className={styles.swiperNav}>
        <div className={`swiper-prev ${styles.prev}`} />
        <div className={`swiper-next ${styles.next}`} />
      </div>
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        className={styles.swiperImages}
        navigation={{
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next',
        }}
        pagination={{
          el: `.swiper-pagination.id-${id}`,
          type: 'bullets',
          clickable: true,
        }}
        loop={items.length > 1}
        loopAdditionalSlides={items.length - initialSlidesPerView}
        centeredSlides={true}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        spaceBetween='10%'
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          601: {
            slidesPerView: 2,
          },
          1025: {
            slidesPerView: 3,
          },
        }}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <SlideImage content={item} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

const SwiperText = ({ items, id, hasButton, textIndex }) => {
  let maxBodyLength = 0
  let placeholderSlide = items[0]
  items.forEach((item) => {
    let measure = item.excerpt?.length
    if (measure > maxBodyLength) {
      maxBodyLength = measure
      placeholderSlide = item
    }
  })

  const hasButtonClass = hasButton ? styles.hasButton : ''

  return (
    <div className={styles.swiperText}>
      {items?.map((item, i) => {
        const isActive = i === textIndex
        return <SlideText key={i} content={item} isActive={isActive} />
      })}
      <SlideText content={placeholderSlide} isPlaceholder={true} />
      <div
        className={`swiper-pagination id-${id} ${styles.pagination} ${hasButtonClass}`}
      ></div>
    </div>
  )
}

const SlideImage = ({ content, isActive }) => {
  const { image, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <LinkWrap
      className={`${styles.slideImageInner} ${isActiveClass}`}
      to={link}
    >
      <ImageWrap image={image} aspectRatio={4 / 3} />
    </LinkWrap>
  )
}

const SlideText = ({ content, isActive, isPlaceholder }) => {
  const { pretitle, title, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isPlaceholderClass = isPlaceholder ? styles.isPlaceholder : ''
  return (
    <LinkWrap
      className={`${styles.slideText} ${isActiveClass}  ${isPlaceholderClass}`}
      to={link}
    >
      {pretitle && <h6>{pretitle}</h6>}
      <h4>{title}</h4>
    </LinkWrap>
  )
}

export default Slideshow
